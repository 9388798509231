import React from 'react';
import MemoryBoard from '../components/MemoryBoard';
import DailyQuote from '../components/DailyQuote';
import LatestQuestion from '../components/LatestQuestion';
import CurrentTime from '../components/CurrentTime';
import LogWorkout from '../components/LogWorkout';

const Dashboard: React.FC = () => {
  return (
    <div className="bg-cover bg-center min-h-screen" style={{ backgroundImage: "url('/images/dashboard-bg.jpg')" }}>
      <div className="flex flex-col justify-center items-center bg-gradient-to-b from-black via-transparent to-black px-4 sm:px-8 py-8 min-h-screen">
        <div className="flex flex-col gap-6 border-zinc-800 bg-zinc-900 bg-opacity-90 shadow-lg p-6 md:p-8 border rounded-lg w-full max-w-6xl">
          <h1 className="font-bold text-3xl text-center text-red-500 sm:text-4xl">Dashboard</h1>
          <p className="mb-6 text-center text-zinc-300">
            Welcome to your dashboard! Here you can access all the content filled with our love.
          </p>
          <LogWorkout /> 
          <CurrentTime />
          <div className="flex md:flex-row flex-col gap-6">
            <div className="flex flex-grow md:w-1/2">
              <DailyQuote />
            </div>
            <div className="flex flex-grow md:w-1/2">
              <MemoryBoard />
            </div>
          </div>
          <LatestQuestion />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;