import React, { useState } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../contexts/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Workout {
  name: string;
  duration: string;
  distance: string;
  distanceUnit: string;
  calories: string;
}

const LogWorkout: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workout, setWorkout] = useState<Workout>({
    name: '',
    duration: '',
    distance: '',
    distanceUnit: 'miles',
    calories: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { username } = useAuth();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setWorkout((prevWorkout) => ({
      ...prevWorkout,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const apiUrl =
        process.env.NODE_ENV === 'production'
          ? `${process.env.REACT_APP_API_BASE_URL_PROD}/api/log-workout`
          : `${process.env.REACT_APP_API_BASE_URL_DEV}/api/log-workout`;

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...workout, username }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      toast.success('Workout logged successfully!');
    } catch (error) {
      console.error('Error logging workout:', error);
      toast.error('Failed to log workout. Please try again.');
    } finally {
      setLoading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <button className="text-white btn btn-primary" onClick={() => setIsModalOpen(true)}>
        Log Workout
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Log Workout"
        className="bg-gray-900 mx-auto p-8 rounded max-w-xl text-white modal"
        overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
      >
        <h2 className="mb-6 font-bold text-2xl text-center">Log Your Workout</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block mb-1 font-medium text-gray-300 text-sm">Workout Name</label>
            <input
              type="text"
              name="name"
              value={workout.name}
              onChange={handleInputChange}
              required
              className="border-gray-600 bg-gray-700 p-2 border rounded focus:ring-2 focus:ring-red-500 w-full focus:outline-none"
            />
          </div>
          <div>
            <label className="block mb-1 font-medium text-gray-300 text-sm">Duration (minutes)</label>
            <input
              type="number"
              name="duration"
              value={workout.duration}
              onChange={handleInputChange}
              required
              className="border-gray-600 bg-gray-700 p-2 border rounded focus:ring-2 focus:ring-red-500 w-full focus:outline-none"
            />
          </div>
          <div>
            <label className="block mb-1 font-medium text-gray-300 text-sm">Distance</label>
            <input
              type="number"
              name="distance"
              value={workout.distance}
              onChange={handleInputChange}
              className="border-gray-600 bg-gray-700 p-2 border rounded focus:ring-2 focus:ring-red-500 w-full focus:outline-none"
            />
          </div>
          <div>
            <label className="block mb-1 font-medium text-gray-300 text-sm">Distance Unit</label>
            <select
              name="distanceUnit"
              value={workout.distanceUnit}
              onChange={handleInputChange}
              className="border-gray-600 bg-gray-700 p-2 border rounded focus:ring-2 focus:ring-red-500 w-full focus:outline-none"
            >
              <option value="km">Kilometers</option>
              <option value="miles">Miles</option>
            </select>
          </div>
          <div>
            <label className="block mb-1 font-medium text-gray-300 text-sm">Calories Burned</label>
            <input
              type="number"
              name="calories"
              value={workout.calories}
              onChange={handleInputChange}
              required
              className="border-gray-600 bg-gray-700 p-2 border rounded focus:ring-2 focus:ring-red-500 w-full focus:outline-none"
            />
          </div>
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="bg-gray-500 hover:bg-gray-600 px-4 py-2 rounded text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className={`${
                loading ? 'bg-red-400' : 'bg-red-500 hover:bg-red-600'
              } px-4 py-2 rounded text-white`}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
          {error && <p className="mt-4 text-center text-red-500">{error}</p>}
        </form>
      </Modal>
    </div>
  );
};

export default LogWorkout;
