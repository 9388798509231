import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Image,
  Divider,
  Icon,
  keyframes,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

const bounceSideToSide = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
`;

const Floggers4ChangePage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [charitySpankTotal, setCharitySpankTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchCharitySpankTotal = async () => {
      try {
        const apiBaseUrl =
          process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_API_BASE_URL_PROD
            : process.env.REACT_APP_API_BASE_URL_DEV;

        if (!apiBaseUrl) {
          throw new Error('API base URL is not defined in environment variables.');
        }

        const response = await axios.get(`${apiBaseUrl}/api/charitySpankTotal`, {
          params: { charityName: 'Floggers4Change' }
        });
        setCharitySpankTotal(response.data.charitySpankTotal);
      } catch (error) {
        console.error('Error fetching charity spank total:', error);
        setCharitySpankTotal(0);
      } finally {
        setLoading(false);
      }
    };

    fetchCharitySpankTotal();
  }, []);

  const handleDonateClick = () => {
    onOpen();
  };

  return (
    <Box
      bgGradient="linear(to-b, blackAlpha.900, gray.800)"
      color="white"
      minH="100vh"
      p={{ base: 6, md: 8 }}
      textAlign="center"
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        bgGradient="linear(to-r, pink.600 0%, transparent 50%, pink.600 100%)"
        opacity="0.3"
        zIndex="-1"
      />

      <VStack spacing={{ base: 8, md: 10 }} maxW="4xl" mx="auto" py={12}>
        <Heading
          as="h1"
          size={{ base: 'xl', md: '4xl' }}
          color="pink.400"
          fontWeight="extrabold"
          letterSpacing="widest"
          textTransform="uppercase"
          lineHeight="1.2"
          textShadow="0 5px 15px rgba(0, 0, 0, 0.3)"
          maxW="100vw"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          Floggers
          <Text as="span" color="red.500" display="inline" fontSize={{ base: '2xl', md: '5xl' }}>
            4
          </Text>
          Change
        </Heading>

        <Text fontSize={{ base: 'lg', md: '2xl' }} color="gray.200" fontWeight="medium">
          At Floggers4Change, we believe love should be passionate, exciting, and adventurous.
        </Text>
        <Text fontSize={{ base: 'md', md: 'lg' }} maxW="3xl" color="gray.400" lineHeight="1.8">
          Our mission is to help couples rediscover their spark by providing access to a wide range
          of erotic toys that can help spice up their love life. Many couples face challenges in
          maintaining intimacy, and we are here to help. Through our donations, we ensure that even
          struggling couples can reignite passion and strengthen their relationships.
        </Text>
        <Divider borderColor="gray.600" my={6} opacity="0.6" />

        {/* Display total spanks donated with button */}
        {loading ? (
          <Text fontSize={{ base: '2xl', md: '3xl' }} fontWeight="bold" color="gray.400">
            Loading total spanks donated...
          </Text>
        ) : (
          <Box
            bgGradient="linear(to-r, gray.700, gray.800)"
            p={{ base: 4, md: 6 }}
            borderRadius="md"
            boxShadow="2xl"
            textAlign="center"
            maxW={{ base: '95%', md: '80%', lg: '60%' }}
            mx="auto"
            mt={6}
            position="relative"
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              w="100%"
              h="100%"
              bg="rgba(0, 0, 0, 0.4)"
              borderRadius="md"
              zIndex="0"
            />
            <Box position="relative" zIndex="1">
              <Text
                fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}
                fontWeight="bold"
                color="yellow.400"
                textShadow="1px 1px 5px rgba(0, 0, 0, 0.8)"
                lineHeight="1.2"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                🌟 Total Spanks Donated 🌟
              </Text>
              <Text
                fontSize={{ base: '5xl', md: '6xl', lg: '7xl' }}
                fontWeight="extrabold"
                color="yellow.300"
                mt={4}
                textShadow="1px 1px 8px rgba(0, 0, 0, 0.8)"
                lineHeight="1.2"
              >
                {charitySpankTotal.toLocaleString()}
              </Text>

              {/* Original Styled Donate Button */}
              <Box
                bgGradient="linear(to-r, pink.500, red.500)"
                p="2px"
                borderRadius="md"
                boxShadow="2xl"
                mt={6} // Space button from text
                display="inline-block"
              >
                <Button
                  colorScheme="whiteAlpha"
                  size={{ base: 'md', md: 'lg' }}
                  onClick={handleDonateClick}
                  bg="black"
                  borderRadius="md"
                  _hover={{
                    transform: 'scale(1.1)',
                    boxShadow: '0px 0px 20px rgba(255, 0, 128, 0.8)',
                  }}
                  _focus={{ boxShadow: 'none' }}
                  leftIcon={(
                    <Icon
                      as={ArrowForwardIcon}
                      w={6}
                      h={6}
                      color="pink.400"
                      animation={`${bounceSideToSide} 2s infinite`}
                    />
                  )}
                >
                  Donate Now
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold" color="white" textShadow="0 2px 10px rgba(255, 255, 255, 0.2)">
          Join us in keeping love alive!
        </Text>

        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={6}
          mt={8}
          justify="center"
          align="center"
        >
          <Image
            src="/images/4change/couple1.png"
            alt="Couple Image"
            boxSize={{ base: '250px', md: '350px' }}
            objectFit="cover"
            borderRadius="lg"
            boxShadow="2xl"
            transition="transform 0.4s ease"
            _hover={{ transform: 'translateY(-10px) scale(1.05)' }}
          />
          <Image
            src="/images/4change/lady1.png"
            alt="Lady Image"
            boxSize={{ base: '250px', md: '350px' }}
            objectFit="cover"
            borderRadius="lg"
            boxShadow="2xl"
            transition="transform 0.4s ease"
            _hover={{ transform: 'translateY(-10px) scale(1.05)' }}
          />
        </Stack>

        {/* Modal for "We are still working on this" */}
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent bg="gray.800" color="white">
            <ModalHeader>Notice</ModalHeader>
            <ModalBody>
              We are still working on this.
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="pink" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <VStack mt={12} spacing={8} align="center">
          <Image
            src="/images/4change/couple2.png"
            alt="Happy Couple Testimonial"
            boxSize={{ base: '200px', md: '300px' }}
            objectFit="cover"
            borderRadius="lg"
            boxShadow="lg"
            transition="transform 0.4s ease"
            _hover={{ transform: 'scale(1.05)' }}
          />
          <Box textAlign="center" maxW="3xl" px={{ base: 4, md: 0 }}>
            <Text fontSize={{ base: 'lg', md: 'xl' }} color="gray.300" fontStyle="italic">
              "We were on the verge of giving up. Our marriage was in shambles, but thanks to Floggers4Change, we were able to reconnect in ways we never thought possible. It saved our relationship, and we’re stronger than ever!"
            </Text>
            <Text fontSize={{ base: 'md', md: 'lg' }} color="pink.400" mt={4} fontWeight="bold">
              — Jack & Jill Smith
            </Text>
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Floggers4ChangePage;
